import React from 'react';

// eslint-disable-next-line arrow-body-style
const NotFound = () => {
  return (
    <main>
      <p>Sorry, page not found!</p>
    </main>
  );
};

export default NotFound;
